<template>
    <UIGridRow class="mx-0 mx-lg-auto">
        <UIGridCol class="d-none d-lg-block" lg="5 pl-0 image-col">
            <div class="imagery">
                <div class="background">
                    <UIImage
                        src="images/home-page/sign-up-newsletter-men.png"
                        :resource="true"
                        :width="201"
                        alt="Newsletter person"
                        class="newsletter-person"
                    />
                    <UIImage
                        src="images/home-page/sign-up-newsletter-send.png"
                        :resource="true"
                        :width="170"
                        alt="Newsletter icon"
                    />
                </div>
            </div>
        </UIGridCol>
        <UIGridCol cols="12" lg="7" class="pr-0 pl-0">
            <div class="sign-up">
                <div class="text p-3 py-4 py-md-auto px-md-5">
                    <transition name="slide-fade" mode="out-in">
                        <div v-if="!submitted" key="form" class="wrapper">
                            <h2 class="mt-0 title">
                                {{ translate('title') }}
                            </h2>
                            <p class="description">
                                {{ translate('bodytext') }}
                            </p>
                            <div class="form">
                                <UIFormInput
                                    id="email"
                                    v-model="email"
                                    name="email"
                                    type="email"
                                    required
                                    autocorrect="off"
                                    :placeholder="translate('inputPlaceholder')"
                                    validation="email"
                                    class="mb-3 mb-md-0"
                                />
                                <UIButton type="submit" variant="primary" class="button" @click="signUp">
                                    {{ translate('button') }}
                                </UIButton>
                            </div>
                        </div>
                        <div v-else key="success" class="description text-center">
                            <p class="mb-0">
                                {{ translate('succes') }}
                            </p>
                        </div>
                    </transition>
                </div>
            </div>
        </UIGridCol>
    </UIGridRow>
</template>

<script>
import { UIGridRow, UIGridCol, UIImage, UIFormInput, UIButton } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'NewsletterComponent',
    components: {
        UIGridRow,
        UIGridCol,
        UIImage,
        UIFormInput,
        UIButton,
    },
    mixins: [TranslationMixin],
    data() {
        return {
            email: '',
            submitted: false,
            alreadyExists: false,
        };
    },
    methods: {
        async signUp() {
            const contact = {
                email: this.email,
                attributes: {
                    REGION: this.$locale.region,
                    LANGUAGE: this.$locale.language,
                    SUBSCRIBED_FROM: 'homepage',
                },
            };
            try {
                await this.$api.newsletter.getSubscriptionStatus(this.email);
                this.submitted = true;
                this.alreadyExists = true;
            } catch {
                this.$gtm?.push({ event: 'event_homepage_newsletter_signup' });
                this.$analytics?.event('email_sign_up');
                await this.$api.newsletter.addNewContact(contact);
                this.submitted = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
    transition: all 1s ease;
}
.slide-fade-leave-active {
    transition: all 0.6s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(15px);
    opacity: 0;
}

.image-col {
    padding-right: 1rem;
    max-width: 400px;
}

.imagery {
    padding-left: 90px;

    .background {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f3839d;
        height: 216px;
        border-radius: $border-radius-default;

        img {
            align-self: auto;
        }
    }

    .newsletter-person {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.sign-up {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;
    min-height: 270px;

    background-color: $color-grey-8;
    border-radius: $border-radius-default;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;

        background-image: url(https://cdn.dundle.com/resources/images/about-page/pattern-dundle-white.svg);
        background-position: center center;
        background-size: 175px 183px;
        opacity: 0.1;
        background-repeat: repeat;
    }

    .text {
        display: flex;
        flex-direction: column;

        .title,
        .description {
            color: $color-header;
        }
    }

    .form {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 768px) {
            flex-direction: row;

            .button {
                min-width: 175px;
                margin-left: 1rem;
            }
        }
    }

    .wrapper {
        z-index: 1;
    }

    @media screen and (min-width: 768px) {
        min-height: auto;
        height: 216px;
    }
}
</style>

<i18n></i18n>
