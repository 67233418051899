<template>
    <div class="app-promotion">
        <span class="title mx-auto mb-1 mt-4">{{ translate('title1') }}</span>
        <span class="title mx-auto">{{ translate('title2') }}</span>
        <p class="body-text">
            {{ translate('bodytext') }}
        </p>
        <UIImage src="images/home-page/dundle-ios-app.png" :resource="true" :width="180" ratio="3:5" class="my-3" />
        <div class="usps">
            <p class="text-white mb-2">
                {{ translate('usp1') }}
            </p>
            <p class="text-white">
                {{ translate('usp2') }}
            </p>
        </div>
        <div class="app-links">
            <a
                href="https://play.google.com/store/apps/details?id=com.dundle.app"
                target="_blank"
                aria-label="Download the Dundle app on Google Play"
                @click="clickLink"
                ><nuxt-resource
                    :src="`images/occasion/app/google-play-badge-${
                        isMarketingRegion('app') ? $locale.language : 'en'
                    }.svg`"
                    class="app-icon"
                    alt="Google Play icon"
                    lazy
                    :width="300"
                    :height="89"
            /></a>
            <a
                href="https://apps.apple.com/nl/app/dundle-prepaid-cards-egifts/id1605958640"
                target="_blank"
                aria-label="Download the Dundle app on the App Store"
                @click="clickLink"
                ><nuxt-resource
                    :src="`images/occasion/app/app-store-badge-${
                        isMarketingRegion('app') ? $locale.language : 'en'
                    }.svg`"
                    class="app-icon"
                    alt="Google Play icon"
                    lazy
                    :width="300"
                    :height="89"
            /></a>
        </div>
        <div class="circle" />
    </div>
</template>

<script>
import { UIImage } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';
import MarketingMixin from '~/mixins/MarketingMixin';

export default {
    name: 'AppPromotionComponent',
    components: {
        UIImage,
    },
    mixins: [TranslationMixin, MarketingMixin],
    methods: {
        clickLink() {
            this.$gtm?.push({ event: 'event_homepage_app_marketing_clicked' });

            this.$analytics.event('view_promotion', {
                creative_name: 'Homepage Banner',
                creative_slot: 'homepage_banner',
                promotion_id: 'app_promotion_default',
                promotion_name: 'App Promotion Standard',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.app-promotion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #007dd3;
    padding: 1rem;
    border-radius: $border-radius-default;
    position: relative;
    overflow: hidden;

    .title {
        font-size: 1.3rem;
        line-height: 1.56rem;
        font-weight: 900;
        color: $color-grey-9;
        background-color: $color-header;
        z-index: 1;
        box-decoration-break: clone;
        padding: 0 0.2rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
        position: relative;

        @media screen and (min-width: 1024px) {
            color: $color-header;
            background-color: $color-grey-9;
            width: min-content;
            text-transform: none;
            line-height: 1.7rem;
        }
    }

    .body-text {
        color: $color-text-inverted;
        font-size: 1rem;
        line-height: 1.2rem;
        text-align: center;
    }

    img {
        align-self: auto;
        z-index: 1;
    }

    .usps {
        margin-bottom: 1.773rem;
        p {
            margin-bottom: 0;
        }
    }

    .usps,
    .app-links {
        z-index: 1;
    }

    .app-links {
        display: flex;
    }

    .app-icon {
        height: 3rem;
        z-index: 1;
        @media screen and (min-width: 768px) {
            max-height: 2rem;
        }
        @media (min-width: 576px) and (max-width: 768px) {
            margin-top: 1rem;
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(https://cdn.dundle.com/resources/images/about-page/pattern-dundle-white.svg);
        background-position: center center;
        background-size: 175px 183px;
        opacity: 0.05;
        background-repeat: repeat;
    }

    .circle {
        &::before {
            content: '';
            position: absolute;
            width: 200%;
            height: 100%;
            border-radius: 50%;
            background-color: $color-section-bg;
            bottom: -50%;
            left: -50%;
            z-index: 0;
        }
    }
}
</style>

<i18n></i18n>
