<template>
    <nuxt-link :to="getLink" :title="brand ? $brand.getName(brand) : title">
        <div class="card" :class="variant">
            <div v-if="brand && onSale">
                <div class="sales-ribbon" :class="useOccasionClass ? occasion.slug : false" />
            </div>
            <div
                class="image"
                :class="[{ background: !brand }, { category: image && !brand }]"
                :style="`background-color: ${colour}`"
            >
                <UIImage
                    v-if="image"
                    :src="image"
                    :width="238"
                    ratio="1:1"
                    :resource="true"
                    alt="Category image"
                    fit="cover"
                    class="background category"
                />

                <UIImage
                    v-if="brand"
                    :resource="true"
                    lazy
                    :src="$brand.getImage340(brand)"
                    class="brand-cover"
                    fit="cover"
                    :width="170"
                    ratio="10:7"
                    :alt="`Card image of ${$brand.getName(brand)}`"
                    :responsive-widths="[150, 170]"
                    sizes="(max-width: 768px) 150px, 170px"
                />
            </div>
            <div class="info">
                <p class="mt-2 text">
                    {{ title || $brand.getName(brand) }}
                </p>
                <p v-if="brand" class="subtitle">
                    {{ renderPriceRange(brand) }}
                </p>
            </div>
        </div>
    </nuxt-link>
</template>

<script>
import { UIImage } from '@dundle/ui/components';
import { mapGetters } from 'vuex';

export default {
    components: {
        UIImage,
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        variant: {
            type: String,
            required: false,
            default: 'small',
            validator: value => {
                return ['small', 'large'].includes(value);
            },
        },
        brand: {
            type: Object,
            required: false,
        },
        link: {
            type: String,
            required: false,
        },
        link_query_params: {
            type: String,
            required: false,
        },
        image: {
            type: String,
            required: false,
        },
        colour: {
            type: String,
            required: false,
        },
    },
    computed: {
        ...mapGetters({
            occasion: 'occasion/getActiveOccasionObject',
        }),
        getLink() {
            let link = this.brand ? this.$brand.getPath(this.brand) : this.link;
            if (this.link_query_params) {
                link = link + this.link_query_params;
            }
            return link;
        },
        onSale() {
            let brandOnSale = false;
            for (const product in this.brand.products) {
                if (this.$product.getDiscount(this.brand.products[product]) > 0) brandOnSale = true;
            }
            return brandOnSale;
        },
        isOccasionBrand() {
            if (!this.onSale) return false;
            return this.occasion?.brands.includes(this.brand);
        },
        useOccasionClass() {
            if (this.onSale && this.isOccasionBrand) return true;
            return false;
        },
    },
    methods: {
        renderPriceRange(brand) {
            if (brand.products.length > 1 && brand.products[0].faceValue) {
                // For "custom currency" products such as in-game credit we want to display the range in it's cutom unit type
                if (brand.products[0].unitType === 'custom') {
                    return `${brand.products[0].unitAmount} ${brand.products[0].unitName} - ${
                        brand.products[brand.products.length - 1].unitAmount
                    } ${brand.products[brand.products.length - 1].unitName}`;
                }
                // Creates a string that displays the face value range from the first product to the last product
                return `${this.$formatNumber(
                    brand.products[0].faceValue,
                    brand.selectedCurrency || this.$locale.currency
                )} - ${this.$formatNumber(
                    brand.products[brand.products.length - 1].faceValue,
                    brand.selectedCurrency || this.$locale.currency
                )}`;
            }
            // For games we just want 1 price, converted from eur to the local currency
            else if (brand.products.length) {
                return this.$formatCurrency(this.$product.getPrice(brand.products[0]));
            }
            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    position: relative;
    overflow: visible;

    .sales-ribbon {
        border: none;
        position: absolute;
        z-index: 1;

        &:not(.blackfriday) {
            width: 65px;
            height: 65px;
            right: -1px;
            top: -2px;
            background: url(https://cdn.dundle.com/resources/images/general/label-sale.svg) no-repeat;
        }
        &.blackfriday {
            width: 115px;
            height: 110px;
            right: -10px;
            top: -2px;
            background: url(https://cdn.dundle.com/resources/images/occasion/blackfriday/label-black-friday-2023.svg)
                no-repeat;
        }
    }

    .image {
        border-radius: $border-radius-default;
        border: none;
        margin: 2px 2px 0;
        &.background {
            height: 200px;
            width: 200px;
            @media screen and (min-width: 768px) {
                height: 175px;
                max-width: 100%;
                width: auto;
            }
        }
        height: 200px;
        width: 200px;
        @media screen and (min-width: 768px) {
            height: 188px;
            width: 188px;
        }
    }

    .text {
        font-weight: bold;
        color: $color-primary-dark;
        margin-bottom: 0;
    }

    .subtitle {
        color: #84989b;
        font-size: 0.75rem;
        font-weight: bold;
    }

    &:hover {
        cursor: pointer;

        .info {
            text-decoration: underline;
        }
    }
}

.large {
    .image {
        @media screen and (min-width: 768px) {
            width: 238px;
            height: 238px;

            .brand-cover {
                width: 170px;
            }
        }
    }
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .background {
        object-fit: cover;
        @media screen and (min-width: 768px) {
            width: 60%;
        }

        &.category {
            z-index: 1;
        }
    }

    .brand-cover {
        border-radius: $border-radius-default;
    }

    img {
        align-self: auto;
    }

    &.background {
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;

            background-image: url(https://cdn.dundle.com/resources/images/about-page/pattern-dundle-white.svg);
            background-position: center center;
            background-size: 175px 183px;
            opacity: 0.1;
            background-repeat: repeat;
        }
    }

    @media screen and (max-width: 768px) {
        &.category {
            width: 150px;
            height: 150px;
        }
    }
}

.small {
    .brand-cover {
        @media screen and (min-width: 768px) {
            width: 140px;
        }
    }
}
</style>
